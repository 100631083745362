import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Meta from "../components/meta"

export default () => (
  <Layout>
    <Meta title="送信完了" />
    <div className="p-4">
      <div className="bg-white py-16 rounded-lg shadow-xl relative max-w-sm mx-auto">
        <p className="text-center text-gray-700 font-bold text-xl mb-3">
          ありがとうございます
        </p>
        <p className="text-gray-600 text-sm text-center">
          お問い合わせを受け付けました。
        </p>
        <div className="flex justify-center items-center mt-6">
          <Link
            to={"/"}
            className="bg-red-500 text-white font-bold px-8 py-2 rounded-lg hover:opacity-75"
          >
            戻る
          </Link>
        </div>
        <div className="absolute w-full">
          <svg
            className="fill-current text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fillOpacity={1}
              d="M0,64L120,90.7C240,117,480,171,720,176C960,181,1200,139,1320,117.3L1440,
96L1440,320L1320,320C1200,320,960,320,720,
320C480,320,240,320,120,320L0,320Z"
            />
          </svg>
          <div className="h-6 w-full bg-red-500 rounded-b-lg" />
        </div>
      </div>
    </div>
  </Layout>
)
